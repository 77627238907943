@use 'sass:math';
@import "./src/styles/Library";
/* App */

/*
    Project customizations here
    Overides default dash styles
*/

.table-bg-yellow {
    background-color: rgba(yellow, .2);
}

.table-bg-red {
    background-color: #FFEBEB;
}

.table-bg-green {
    background-color: #F6FFED;
}

.table-bg-blue {
    background-color: rgba(blue, .2);
}