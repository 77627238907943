.status {
    color: rgba($dark1, .8);
}

.status-active {
    background-color: rgba($statusGreen, .6);
}

.status-inactive {
    background-color: rgba($statusDarkOrange, .6);
}

.status-limitReached {
    background-color: rgba($statusRed, .6);
}

.status-new {
    background-color: rgba($statusYellow, .6);
}

.status-returned {
    background-color: rgba($statusLightOrange, .6);
}

.status-cancelled {
    background-color: rgba($statusGray, .6);
}

.status-review {
    background-color: rgba($statusYellow, 0.6);
}

.status-shipped, .status-shipping {
    background-color: rgba($statusBlue, .6);
}

.status-approved {
    background-color: rgba($statusDarkOrange, .6);
}

.status-processing {
    background-color: rgba($statusDarkOrange, .6);
}

.status-denied {
    background-color: rgba($statusRed, .6);
}

.status-delivered {
    background-color: rgba($statusTeal, .6);
}
