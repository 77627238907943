// Overwrite Variables Here

$primary: #0dc16d;
$secondary:#ffb14d;
$alt:#ff3b90;

$primaryLight: #e7f7fd;
$primaryDark: #1b2439;

//statuses
$statusYellow: #FFF38B;
$statusPink: #FF7DCA;
$statusPurple: #D899FC;
$statusLightOrange: #D2B29D;
$statusDarkOrange: #FFB155;
$statusGreen: #98F8AA;
$statusTeal: #47D792;
$statusBlue: #77CBFF;
$statusGray: #CCCCCC;
$statusRed: #FF7276;